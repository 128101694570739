import { takeEvery, all, select, call } from 'redux-saga/effects';
import {
  createRequestInstance,
  watchRequests,
  // sendRequest,
  success,
  error
} from 'redux-saga-requests';
import { createDriver } from 'redux-saga-requests-axios';
import axios from 'axios';

import * as actionTypes from '../actions/actionTypes';
import {
  logoutSaga,
  authFlowSaga,
  redirectPathSaga,
  debounceVersionCheck,
  versionCheckSaga,
  startBackgroundTokenExpiryCheck,
  reloadPostFilterChangeSaga
} from './auth';
import { debounceSearch } from './search';
import { registerUserSaga } from './register';
import {
  clientSideActionSaga,
  serverArrayChangeSaga,
  updateFormWithServerValuesSaga,
  initializeFormSaga,
  serverEventCompleteSaga,
  serverFieldChangeSaga,
  formEventSaga,
  formSubmitEventSaga,
  workflowTriggerSaga,
  workflowTriggerCompleteSaga,
  formReloadErrorSaga,
  openExternalPageSaga
} from './formEngine';
import { beforeDynamicSaga, afterDynamicSaga } from './dynamic';
import { updateUISettingsSaga } from './ui';
import { tableModalDispatcher, tableRequestDispatcher } from './table';
// import { afterJournalSaga } from './journal';
import {
  downloadFileSaga,
  printPdfSaga,
  saveBlob,
  delayActionSaga
} from './documents';
import { message, notification } from 'antd';

import { LOCATION_CHANGE } from 'connected-react-router';

const axiosInstance = axios.create({
  baseURL: '/api',
  timeout: 360000
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // const errorResponse =
      //   error.response && error.response.data
      //     ? JSON.stringify(error.response.data)
      //     : 'Wystąpił błąd';
      // message.error(errorResponse);
      message.error('Wystąpił błąd');
    }
    return Promise.reject(error);
  }
);

const FrontendVersion = process.env.REACT_APP_GIT_DATE || 'Localhost';

function* onRequestSaga(request, action) {
  const token = yield select((state) => state.auth.token);
  const filter = yield select((state) => state.auth.filter);
  if (!request.headers) request.headers = {};
  request.headers.Authorization = token ? `Bearer ${token}` : '';
  request.headers.FrontendVersion = FrontendVersion;
  if (filter) request.headers.Filter = filter;
  return request;
}

// eslint-disable-next-line require-yield
function* onSuccessSaga(response, action) {
  if (
    action.type === actionTypes.TABLE_EXPORT_FETCH ||
    action.type === actionTypes.JOURNAL_EXPORT_FETCH ||
    action.type === actionTypes.REPORTS_RUN_AND_DOWNLOAD ||
    action.type === actionTypes.LETTER_GENERATE_COMBINED_PRINT_PDF ||
    action.type === actionTypes.DOCUMENTS_DOWNLOAD_AS_A_ZIP ||
    action.type === actionTypes.PROCESSING_FETCH_SAMPLE_IMPORT_FILE ||
    action.type === actionTypes.PROCESSING_GENERATE_REFUND_DATA
  ) {
    return {
      data: {
        blob: response.data,
        contentDisposition: response.headers['content-disposition']
      }
    };
  }
  return response;
}

function* mainSagaTasks() {
  yield all([
    watchRequests({
      takeLatest: false,
      abortOn: actionTypes.AUTH_INITIATE_LOGOUT
    }),
    authFlowSaga(),
    debounceVersionCheck(),
    startBackgroundTokenExpiryCheck(),
    debounceSearch(),
    takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga),
    takeEvery(actionTypes.REGISTER, registerUserSaga),
    takeEvery(LOCATION_CHANGE, redirectPathSaga),
    takeEvery(actionTypes.FORMENGINE_CLIENT_CHANGE, clientSideActionSaga),
    takeEvery(
      actionTypes.FORMENGINE_SERVER_ARRAY_CHANGE,
      serverArrayChangeSaga
    ),
    takeEvery(
      actionTypes.FORMENGINE_SERVER_FIELD_CHANGE,
      serverFieldChangeSaga
    ),
    takeEvery(actionTypes.FORMENGINE_INITIALIZE, initializeFormSaga),
    takeEvery(
      success(actionTypes.FORMENGINE_SERVER_CHANGE),
      updateFormWithServerValuesSaga
    ),
    takeEvery(actionTypes.FORMENGINE_EVENT_TRIGGER, formEventSaga),
    takeEvery(actionTypes.FORMENGINE_SUBMIT_EVENT_TRIGGER, formSubmitEventSaga),
    takeEvery(
      success(actionTypes.FORMENGINE_SERVER_EVENT),
      serverEventCompleteSaga
    ),
    takeEvery(
      success(actionTypes.FORMENGINE_SUBMIT_EVENT_SEND),
      serverEventCompleteSaga
    ),
    takeEvery(actionTypes.FORMENGINE_WORKFLOW_TRIGGER, workflowTriggerSaga),
    takeEvery(
      success(actionTypes.FORMENGINE_WORKFLOW_TRIGGER_SEND),
      workflowTriggerCompleteSaga
    ),
    // takeEvery(success(actionTypes.JOURNAL_NEW_ENTRY), afterJournalSaga),
    takeEvery(actionTypes.FETCH_DYNAMIC_PAGE, beforeDynamicSaga),
    takeEvery(
      success(actionTypes.FETCH_DYNAMIC_PAGE_REQUEST),
      afterDynamicSaga
    ),
    takeEvery(success(actionTypes.DOCUMENTS_DOWNLOAD), downloadFileSaga),
    takeEvery(success(actionTypes.DOCUMENTS_PRINT_PDF), printPdfSaga),
    takeEvery(success(actionTypes.TABLE_EXPORT_FETCH), saveBlob),
    takeEvery(success(actionTypes.JOURNAL_EXPORT_FETCH), saveBlob),
    takeEvery(success(actionTypes.REPORTS_RUN_AND_DOWNLOAD), saveBlob),
    takeEvery(success(actionTypes.DOCUMENTS_DOWNLOAD_AS_A_ZIP), saveBlob),
    takeEvery(success(actionTypes.PROCESSING_GENERATE_REFUND_DATA), saveBlob),
    takeEvery(
      success(actionTypes.PROCESSING_FETCH_SAMPLE_IMPORT_FILE),
      saveBlob
    ),
    takeEvery(success(actionTypes.CHECK_VERSION_REQUEST), versionCheckSaga),
    takeEvery(
      error(actionTypes.FORMENGINE_KEEP_SESSION_ALIVE),
      formReloadErrorSaga
    ),
    takeEvery(actionTypes.OPEN_EXTERNAL_PAGE, openExternalPageSaga),
    takeEvery(actionTypes.DELAYED_ACTION, delayActionSaga),
    takeEvery(actionTypes.TABLE_OPEN_MODAL, tableModalDispatcher),
    takeEvery(actionTypes.TABLE_RECORDS_FETCH, tableRequestDispatcher),
    takeEvery(actionTypes.SET_FILTER, reloadPostFilterChangeSaga),
    takeEvery(success(actionTypes.FETCH_MENU), updateUISettingsSaga)
  ]);
}

export function* rootSaga() {
  yield createRequestInstance({
    driver: createDriver(axiosInstance),
    onRequest: onRequestSaga,
    onSuccess: onSuccessSaga
  });
  while (true) {
    try {
      yield call(mainSagaTasks);
    } catch (error) {
      notification.error({
        className: `notification-error`,
        message: 'Saga error, please report - see console',
        placement: 'topRight',
        duration: 10
      });
      console.log('Saga error', error);
    }
  }
}
